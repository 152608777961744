<template>
  <div>
    <div class="container" align="left">
      <div class="row d-flex mt-5">
        <h3 class="mb-4"><strong>개인정보처리방침</strong></h3>
        <p align="left">
          SK핀크스(주)는 고객의 개인정보를 매우 중요하게 생각하며, ‘개인정보보호법’ 등 관련법령을 준수하고 있습니다.
          SK핀크스(주)는 개인정보 처리방침을 통하여 고객께서 제공하신 개인정보가 어떠한 용도와 방식으로 처리되고 있고,
          개인정보보호를 위하여 어떤 조치가 취해지고 있는지 알려드립니다.
        </p>
        <p style="margin: 0">SK핀크스(주) 의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.</p>
        <p class="numText2">
          <small
            >※ “개인정보의 처리”란 개인정보의 수집, 생성, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정(訂正), 복구,
            이용, 제공, 공개, 파기(破棄), 그 밖에 이와 유사한 행위를 말합니다.
          </small>
        </p>

        <div class="bookmark-links">
          <a href="#section-01">제 1조 개인정보 처리 목적</a>
          <a href="#section-02">제 2조 개인정보 처리항목 및 방법</a>
          <a href="#section-03">제 3조 개인정보 보유•이용기간 및 파기</a>
          <a href="#section-04">제 4조 개인정보 제3자 제공</a>
          <a href="#section-05">제 5조 개인정보 처리 위탁</a>
          <a href="#section-06">제 6조 정보주체와 법정대리인의 권리•의무 및 행사방법</a>
          <a href="#section-07">제 7조 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</a>
          <a href="#section-08">제 8조 만 18세 미만 미성년자의 개인정보 보호</a>
          <a href="#section-09">제 9조 개인정보 보호책임자</a>
          <a href="#section-10">제 10조 개인정보의 안전성 확보조치에 관한 사항</a>
          <a href="#section-11">제 11조 광고성 정보 전송</a>
          <a href="#section-12">제 12조 개인정보 처리방침의 변경에 관한 사항</a>
        </div>

        <div class="container">
          <div class="row privacy-detail mb-5">
            <section id="section-01" class="mt-5">
              <h5><strong>제1조 개인정보 처리 목적</strong></h5>
              <p class="numText1">
                1. SK핀크스(주)는 서비스제공을 위하여 다음 각 항목과 같은 목적으로 고객의 개인정보를 처리하고 있습니다.
              </p>

              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb">
                    <td colspan="2" width="20%">구분</td>
                    <td>수집항목</td>
                    <td>수집목적</td>
                    <td width="20%">보유기간</td>
                  </tr>
                  <tr>
                    <td rowspan="2">멤버십</td>
                    <td>필수</td>
                    <td>성명, 연락처, 이메일, 아이디, 비밀번호, 예약내역</td>
                    <td>
                      - 회원제 서비스에 따른 본인 확인 절차<br />
                      - 멤버십 혜택, 약관변경, 고지사항 전달<br />
                      - 문의/상담 및 불만 등에 대한 민원처리
                    </td>
                    <td colspan="2">멤버십 가입 기간 및 탈퇴 후 1년</td>
                  </tr>
                  <tr>
                    <td>선택</td>
                    <td>성명, 휴대폰, 이메일, 생년월일</td>
                    <td>
                      - 호텔 상품 및 프로모션, 이벤트에 대해 SMS, eDM, DM, TM을 통한 홍보, 리서치 등 마케팅 목적으로
                      활용
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">온라인 예약</td>
                    <td rowspan="2">필수</td>
                    <td>성명, 생년월일, 연락처, 이메일</td>
                    <td>
                      - 본인 확인 절차에 활용<br />
                      - 사전 결제 내용 확인 수단<br />
                      - 예약 사항 고지 수단
                    </td>
                    <td rowspan="2">
                      3개월<br />
                      - 예약 완료 고객의 경우<br />
                      체크인일자 기준<br />
                      - 예약 불가 고객의 경우<br />
                      예약신청 일자 기준
                    </td>
                  </tr>
                  <tr>
                    <td>신용카드종류, 신용카드번호, 유효기간</td>
                    <td>- 미 지불건에 대한 결제</td>
                  </tr>
                  <tr>
                    <td>행사문의</td>
                    <td>필수</td>
                    <td>성명, 연락처, 이메일</td>
                    <td>- 예약 및 상담, 문의사항 전달</td>
                    <td>6개월</td>
                  </tr>
                  <tr>
                    <td rowspan="5">인재채용</td>
                    <td rowspan="3">필수</td>
                    <td>사진, 성명(한글, 한자, 영문), 생년월일, 국적</td>
                    <td>- 본인 확인 및 식별, 입사지원 서비스 제공</td>
                    <td rowspan="5">3년</td>
                  </tr>
                  <tr>
                    <td>연락처(유선, 무선), 이메일, 주소</td>
                    <td>- 채용 관련 고지사항 전달 및 결과 안내 등</td>
                  </tr>
                  <tr>
                    <td>학력사항, 병역사항, 희망부서, 희망직무, 희망연봉, 자기소개서</td>
                    <td>- 채용 적합성 판단 및 서류 심사/면접 등의 근거 자료로 활용</td>
                  </tr>
                  <tr>
                    <td rowspan="2">선택</td>
                    <td>
                      경력사항, 최종연봉, 외국어능력, PC활용, 영어회화, 일어회화, 중국어회화, 자격사항, 해외체류 경험
                    </td>
                    <td>- 채용 적합성 판단 및 서류 심사/면접 등의 근거 자료로 활용</td>
                  </tr>
                  <tr>
                    <td>
                      보훈대상여부, 보훈번호, 관계, 장애인여부, 장애종류, 장애급수, 취업보호계층여부, 해당 세부사항
                    </td>
                    <td>- 대상자 가산점 부여</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody align="center">
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">멤버십 - 수집항목</th>
                  </tr>
                  <tr>
                    <td width="10%">필수</td>
                    <td>성명, 연락처, 이메일, 아이디, 비밀번호, 예약내역</td>
                  </tr>
                  <tr>
                    <td>선택</td>
                    <td>성명, 휴대폰, 이메일, 생년월일</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">멤버십 - 수집목적</th>
                  </tr>
                  <tr>
                    <td>필수</td>
                    <td align="left" style="font-size: 0.88rem">
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 회원제 서비스에 따른 본인 확인 절차<br />
                      </div>
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 멤버십 혜택, 약관변경, 고지사항 전달<br />
                      </div>
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 문의/상담 및 불만 등에 대한 민원처리
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>선택</td>
                    <td align="left" style="font-size: 0.9rem">
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 호텔 상품 및 프로모션, 이벤트에 대해 SMS, eDM, DM, TM을 통한 홍보, 리서치 등 마케팅 목적으로
                        활용
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">멤버십 - 보유기간</th>
                  </tr>
                  <tr>
                    <td>필수</td>
                    <td style="font-size: 0.9rem">멤버십 가입 기간 및 탈퇴 후 1년</td>
                  </tr>
                  <tr>
                    <td>선택</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">온라인 예약 - 수집항목</th>
                  </tr>
                  <tr>
                    <td>필수1</td>
                    <td>성명, 생년월일, 연락처, 이메일</td>
                  </tr>
                  <tr>
                    <td>필수2</td>
                    <td>신용카드 종류, 신용카드 번호, 유효기간</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">온라인 예약 - 수집목적</th>
                  </tr>
                  <tr>
                    <td>필수1</td>
                    <td align="left">
                      - 본인 확인 절차에 활용<br />
                      - 사전 결제 내용 확인 수단<br />
                      - 예약 사항 고지 수단
                    </td>
                  </tr>
                  <tr>
                    <td>필수2</td>
                    <td align="left">- 미 지불건에 대한 결제</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">온라인 예약 - 보유기간</th>
                  </tr>
                  <tr>
                    <td>필수1,2</td>
                    <td>
                      <div style="margin-bottom: 0.3rem">3개월<br /></div>
                      <div align="left" style="font-size: 0.88rem; margin-left: 0.7rem; text-indent: -0.7rem">
                        - 예약 완료 고객의 경우 체크인일자 기준<br />
                      </div>
                      <div align="left" style="font-size: 0.9rem; margin-left: 0.7rem; text-indent: -0.7rem">
                        - 예약 불가 고객의 경우 예약신청 일자 기준
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">행사문의 - 수집항목</th>
                  </tr>
                  <tr>
                    <td>필수</td>
                    <td>성명, 연락처, 이메일</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">행사문의 - 수집목적</th>
                  </tr>
                  <tr>
                    <td>필수</td>
                    <td align="left">- 예약 및 상담, 문의사항 전달</td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">행사문의 - 보유기간</th>
                  </tr>
                  <tr>
                    <td>필수</td>
                    <td>6개월</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">인재채용 - 수집항목</th>
                  </tr>
                  <tr>
                    <td>필수1</td>
                    <td>사진, 성명(한글, 한자, 영문), 생년월일, 국적</td>
                  </tr>
                  <tr>
                    <td>필수2</td>
                    <td>연락처(유선, 무선), 이메일, 주소</td>
                  </tr>
                  <tr>
                    <td>필수3</td>
                    <td>학력사항, 병역사항, 희망부서, 희망직무, 희망연봉, 자기소개서</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">인재채용 - 수집목적</th>
                  </tr>
                  <tr>
                    <td>필수1</td>
                    <td align="left" style="font-size: 0.86rem">
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 본인 확인 및 식별, 입사지원 서비스 제공
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>필수2</td>
                    <td align="left" style="font-size: 0.88rem">
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 채용 관련 고지사항 전달 및 결과 안내 등
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>필수3</td>
                    <td align="left" style="font-size: 0.88rem">
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 채용 적합성 판단 및 서류 심사/면접 등의 근거 자료로 활용
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">인재채용 - 보유기간</th>
                  </tr>
                  <tr>
                    <td>필수1,2,3</td>
                    <td>3년</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">인재채용 - 수집항목</th>
                  </tr>
                  <tr>
                    <td>선택1</td>
                    <td>
                      경력사항, 최종연봉, 외국어능력, PC활용, 영어회화, 일어회화, 중국어회화, 자격사항, 해외체류 경험
                    </td>
                  </tr>
                  <tr>
                    <td>선택2</td>
                    <td>
                      보훈대상여부, 보훈번호, 관계, 장애인여부, 장애종류, 장애급수, 취업보호계층여부, 해당 세부사항
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">인재채용 - 수집목적</th>
                  </tr>
                  <tr>
                    <td>선택1</td>
                    <td align="left" style="font-size: 0.88rem">
                      <div style="margin-left: 0.7rem; text-indent: -0.7rem">
                        - 채용 적합성 판단 및 서류 심사/면접 등의 근거 자료로 활용
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>선택2</td>
                    <td align="left">- 대상자 가산점 부여</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="background-color: #f4f2eb">인재채용 - 보유기간</th>
                  </tr>
                  <tr>
                    <td>선택1,2</td>
                    <td>3년</td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1">
                2. SK핀크스(주)는 고객님의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조,
                출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.
              </p>
            </section>
            <section id="section-02" class="mt-5">
              <h5 class="mb-4"><strong>제2조 개인정보 처리항목 및 방법</strong></h5>
              <p class="numText1">
                1. SK핀크스(주)는 서비스제공을 위하여 필요한 최소한의 범위 내에서 제1조에 규정한 개인정보를 수집합니다.
              </p>
              <p class="numText1">2. SK핀크스(주)는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
              <p class="numText2" style="margin-bottom: 8px">가. 홈페이지, 서면양식에 의한 수집</p>
              <p class="numText2" style="margin-bottom: 8px">
                나. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
              </p>
              <p class="numText3">- IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등</p>
            </section>

            <section id="section-03" class="mt-5">
              <h5 class="mb-4"><strong>제3조 개인정보 보유•이용기간 및 파기</strong></h5>
              <p class="numText1">
                1. SK핀크스(주)는 정보주체로부터 동의 받은 ‘보유•이용기간’ 또는 법령에 따른 ‘보유•이용기간’에 따라
                개인정보를 보유할 수 있고, 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 회원의 개인정보를 지체
                없이 파기합니다. 구체적인 파기 시점은 다음과 같습니다.
              </p>
              <p class="numText2">
                가. 회원가입 정보(내부방침): 회원 가입을 탈퇴한 이후로 재방문 인지서비스 제공을 위하여 1년간 보관 이후
                파기 이후 즉시 파기
              </p>

              <p class="numText2">
                나. 온라인 예약정보: 방문 시 서비스 및 응대를 위하여 예약 완료 고객의 경우 체크인 일자 기준 3개월 보관
                후 파기, 예약 불가 고객의 경우 예약 신청 완료일자로부터 3개월 보관 후 파기
              </p>

              <p class="numText2">다. 객실투숙정보(내부방침): 재방문시 서비스 제공을 위하여 5년간 보관 후 파기</p>
              <p class="numText2">라. 배송 정보: 물품 또는 서비스가 인도되거나 제공된 후 파기</p>
              <p class="numText2">
                마. 설문조사, 이벤트 등의 목적을 위하여 수집한 경우: 당해 설문조사, 이벤트 등을 종료한 후 파기
              </p>

              <p class="numText1">
                2. 단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 관리
                의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.
              </p>

              <p class="numText2">가. 계약 또는 청약철회 등에 관한 기록 : 5년</p>
              <p class="numText2">나. 대금결제 및 재화 등의 공급에 관한 기록 : 5년</p>
              <p class="numText2">다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</p>
              <p class="numText2">라. 신용정보 업무처리에 관한 기록 : 3년</p>
              <p class="numText2">마. 로그기록자료, 접속지의 추적자료 : 3개월</p>

              <p class="numText1">
                3. 개인정보유효기간제 실시<br />
                SK핀크스(주)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조 제2항 및 동법 시행령 제16조 제2항에
                따라 온라인 회원 중 1년 이상 당사 서비스를 이용하지 않은 회원의 개인정보를 다른 정보와 별도로 분리하여
                보관하며 3년간 당사 서비스를 이용하지 않는 회원의 경우 자동 탈퇴처리 됩니다. 탈퇴 처리 6개월 전 문자나
                이메일로 통보됩니다. 회원이 변경된 휴대폰 번호나 이메일을 SK핀크스㈜에 통보하지 않아 이전 휴대폰 번호
                등에 따라 통지하여 통보가 되지 않은 경우 회원의 책임으로 합니다.
              </p>
              <p class="numText1">4. 파기방법</p>

              <p class="numText2">가. 종이에 출력된 개인정보: 분쇄기로 분쇄하거나 소각</p>
              <p class="numText2">
                나. 전자적 파일형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제
              </p>
            </section>

            <section id="section-04" class="mt-5">
              <h5 class="mb-4"><strong>제4조 개인정보 개인정보 제3자 제공</strong></h5>
              <p class="numText1">
                1. SK핀크스(주)는 회원의 동의가 있거나 관련 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도
                『개인정보의 수집목적 및 이용목적』에서 고지한 범위를 넘어 회원의 개인정보를 이용하거나 타인 또는
                타기업/기관에 제공 하지 않습니다. 제휴를 통해 회원의 개인정보를 제공하거나 공유하는 경우에는 사전에
                회원에게 제공 받거나 공유하는 자가 누구이며 주된 사업이 무엇인지, 제공 또는 공유되는 개인정보 항목이
                무엇인지, 개인정보를 제공 하거나 공유 하는 목적이 무엇인지 등에 대해 개별적으로 전자우편 또는 서면을
                통해 고지한 후 이에 대한 동의를 구합니다. SK핀크스(주)의 개인정보 제공은 SK핀크스(주)만의 서비스를
                제공하기 위한 것이므로 제공에 대한 동의를 하지 아니하면 정상적인 서비스 제공 및 이용이 불가능 할 수
                있습니다.
              </p>
              <p class="numText1">2. SK핀크스㈜는 개인정보를 제3자에게 제공하지 않습니다.</p>
              <p class="numText1">
                3. 다음의 경우에는 관련 법령의 규정에 의하여 고객 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를
                제외하고는 회원의 동의 없이 제3자에게 개인정보를 제공하는 것이 가능합니다. 단 마목에서 자목까지의 경우는
                공공기간으로 한정합니다.
              </p>

              <p class="numText2">가. 고객으로부터 별도의 동의를 받은 경우</p>
              <p class="numText2">나. 다른 법률에 특별한 규정이 있는 경우</p>
              <p class="numText2">
                다. 고객 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수
                없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는
                경우
              </p>
              <p class="numText2">
                라. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를
                제공하는 경우
              </p>
              <p class="numText2">
                마. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관
                업무를 수행할 수 없는 경우로서 보호위원회의 심의•의결을 거친 경우
              </p>
              <p class="numText2">
                바. 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우
              </p>
              <p class="numText2">사. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</p>
              <p class="numText2">아. 법원의 재판업무 수행을 위하여 필요한 경우</p>
              <p class="numText2">자. 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</p>
            </section>

            <section id="section-05" class="mt-5">
              <h5 class="mb-4"><strong>제5조 개인정보 처리 위탁</strong></h5>
              <p class="numText1">
                1. SK핀크스(주)는 개인정보 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며 관계법령에 따라 위탁 계약
                시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
              </p>

              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f1f0e8">
                    <td>수탁업체</td>
                    <td>수탁범위</td>
                    <td>처리정보</td>
                  </tr>
                  <tr>
                    <td>SK네트웍스서비스(주)</td>
                    <td>홈페이지 운영 및 논리적 및 물리적 데이터 베이스 관리</td>
                    <td>홈페이지 운영 관리 및 전산화된 회원정보 전체</td>
                  </tr>

                  <tr>
                    <td>SK C&C(주)</td>
                    <td>서버 네트워킹 서비스</td>
                    <td>서버 네트워킹 관리</td>
                  </tr>
                  <tr>
                    <td>SK브로드밴드(주)</td>
                    <td>SMS/MMS 발송 서비스</td>
                    <td>고객 연락처</td>
                  </tr>
                  <tr>
                    <td>SCI평가정보(주)</td>
                    <td>아이핀/휴대폰을 통한 본인인증</td>
                    <td>동일인 식별정보(CI) 및 중복가입확인정보(DI)</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody align="center">
                  <tr style="border-top: 2px solid">
                    <th style="background-color: #f4f2eb">수탁업체</th>
                    <td>SK네트웍스서비스(주)</td>
                  </tr>
                  <tr>
                    <th style="background-color: #f4f2eb">수탁범위</th>
                    <td>홈페이지 운영 및 논리적 및 물리적 데이터 베이스 관리</td>
                  </tr>
                  <tr>
                    <th style="background-color: #f4f2eb">처리정보</th>
                    <td>홈페이지 운영 관리 및 전산화된 회원정보 전체</td>
                  </tr>
                  <tr style="border-top: 2px solid">
                    <th style="background-color: #f4f2eb">수탁업체</th>
                    <td>SK C&C(주)</td>
                  </tr>
                  <tr>
                    <th style="background-color: #f4f2eb">수탁범위</th>
                    <td>서버 네트워킹 서비스</td>
                  </tr>
                  <tr>
                    <th style="background-color: #f4f2eb">처리정보</th>
                    <td>서버 네트워킹 관리</td>
                  </tr>
                  <tr style="border-top: 2px solid">
                    <th style="background-color: #f4f2eb">수탁업체</th>
                    <td>SK브로드밴드(주)</td>
                  </tr>
                  <tr>
                    <th style="background-color: #f4f2eb">수탁범위</th>
                    <td>SMS/MMS 발송 서비스</td>
                  </tr>
                  <tr>
                    <th style="background-color: #f4f2eb">처리정보</th>
                    <td>고객 연락처</td>
                  </tr>
                  <tr style="border-top: 2px solid">
                    <th style="background-color: #f4f2eb">수탁업체</th>
                    <td>SCI평가정보(주)</td>
                  </tr>
                  <tr>
                    <th style="background-color: #f4f2eb">수탁범위</th>
                    <td>아이핀/휴대폰을 통한 본인인증</td>
                  </tr>
                  <tr style="border-bottom: 2px solid">
                    <th style="background-color: #f4f2eb">처리정보</th>
                    <td>동일인 식별정보(CI) 및 중복가입확인정보(DI)</td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1">
                2. SK핀크스㈜는 위탁계약 체결 시 개인정보보호의 안전을 기하기 위하여 개인정보보호 관련 지시 엄수,
                위탁업무 수행목적 외 개인정보 처리금지 및 기술적•관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리•감독,
                사고시의 손해배상 등 책임에 관한 사항을 명확히 규정하고 당해 계약 내용을 서면으로 보관하고 있습니다.
                또한 업체가 변경될 경우, SK핀크스(주)는 변경된 업체 명을 홈페이지 내 개인정보처리(취급)방침 화면에
                공지합니다.
              </p>
              <p class="numText1">
                3. SK핀크스㈜는 위탁처리 되는 개인정보가 안전하게 관리될 수 있도록 수탁업체가 규정한 사항을 성실하게
                이행하는지 여부에 대하여 위탁한 업무의 범위 내에서 적절한 감독을 행할 것입니다.
              </p>
            </section>

            <section id="section-06" class="mt-5">
              <h5 class="mb-4"><strong>제6조 정보주체와 법정대리인의 권리•의무 및 행사방법</strong></h5>
              <p class="numText1">
                1. 회원이 원하실 경우 본인의 정보에 대해서는 언제라도 개인정보를 열람하실 수 있으며 보관된 정보의 수정
                및 삭제, 처리정지 요구를 하실 수 있습니다.
              </p>
              <p class="numText1">
                2. “회원의 개인정보 수정”은 로그인(Login)후 마이페이지(MY PAGE)메뉴에서 개인정보 정정 및 탈회, 동의
                철회를 할 수 있습니다. 홈페이지를 통한 개인정보의 열람이나 정정에 불편이 있는 경우에는 개인정보보호법
                시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX), 전화를 통해서도 열람이나 정정을 요청할 수
                있으며 이에 대해서는 자체 없이 처리하겠습니다. 비회원의 경우 개인정보는 이용자가 홈페이지상 직접 열람 및
                정정이 불가하오니 홈페이지 하단 예약전화(064-792-8000)로 전화를 주시기 바랍니다.
              </p>
              <p class="numText1">
                3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
                있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              </p>
              <p class="numText1">
                4. 개인정보 열람 및 처리정지 요구는 개인정보보호법에 의하여 정보주체의 권리가 제한될 수 있습니다.
              </p>
              <p class="numText1">
                5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그
                삭제를 요구할 수 없습니다.
              </p>
              <p class="numText1">
                6. SK핀크스㈜는 정보주체 권리에 따른 열람 요구 등의 경우에 요구를 한 자가 본인이거나 정당한 대리인인지를
                확인합니다.
              </p>
            </section>

            <section id="section-07" class="mt-5">
              <h5 class="mb-4">
                <strong>제7조 개인정보 자동 수집 장치(‘쿠키’’cookie’)의 설치•운영 및 거부에 관한 사항</strong>
              </h5>
              <p class="numText1">
                1. SK핀크스(주)는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)'를 운영합니다. 쿠키란 핀크스
                웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에 보내는 아주 작은 텍스트 파일로서
                이용자의 컴퓨터 하드디스크에 저장됩니다.
              </p>
              <p class="numText1">2. SK핀크스(주)는 다음과 같은 목적으로 쿠키를 사용합니다.</p>
              <p class="numText2">
                가. 이용자의 접속 빈도나 방문 시간 등을 분석하고 이용자의 취향과 관심분야를 파악하여 타겟(Target) 마케팅
                및 서비스 개편 등의 척도로 활용합니다.
              </p>

              <p class="numText2">
                나. SK핀크스(주)에 대한 정보와 관심 있게 둘러본 서비스들에 대한 자취를 추적하여 다음 번 이용 시 개인
                맞춤 서비스를 제공하는데 이용합니다.
              </p>

              <p class="numText2">
                다. SK핀크스(주)가 진행하는 각종 이벤트에서 이용자의 참여 정도 및 방문 회수를 파악하여 차별적인 응모
                기회를 부여하고 개인의 관심 분야에 따라 차별화된 정보를 제공하기 위한 자료로 이용됩니다.
              </p>

              <p class="numText2">
                라. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을
                설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을
                거부 할 수도 있습니다.
              </p>

              <p class="numText3" style="margin-bottom: 0px">쿠키 설치 허용 여부를 지정하는 방법</p>
              <p class="numText3">[Internet Explorer 9.0을 사용하고 있는 경우]</p>

              <p class="numText3">1) [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</p>
              <p class="numText3">2) [개인정보 탭]을 클릭합니다.</p>

              <p class="numText3">3) [개인정보보호 수준]을 설정하시면 됩니다.</p>
              <p class="numText2">
                마. 이용자께서 쿠키 저장을 거부하셨을 경우 SK핀크스(주) 서비스 제공에 어려움이 있습니다.
              </p>
            </section>

            <section id="section-08" class="mt-5">
              <h5 class="mb-4">
                <strong>제8조 만19세 미만 미성년자의 개인정보</strong>
              </h5>
              <p>SK핀크스(주)는 원칙상 만19세 미만 미성년자의 대한 회원가입을 제한하고 있습니다.</p>
            </section>

            <section id="section-09" class="mt-5">
              <h5 class="mb-4">
                <strong>제9조 개인정보 보호책임자</strong>
              </h5>
              <p class="numText1">
                1. SK핀크스(주)는 회원의 개인정보를 보호하고 개인정보에 대한 의견수렴 및 불만 처리를 위하여 아래와 같이
                개인정보보호 책임자를 지정하고 있습니다.
              </p>
              <p class="numText2">
                <strong>개인정보보호 책임자</strong><br />
                - 성명 : 채진혁<br />
                - 소속 : 마케팅팀<br />
                - 직책 : 마케팅팀 팀장<br />
                - 이메일 : chaejh0425@sk.com<br />
                - 전화번호 : 064-793-6050<br /><br />
              </p>
              <p class="numText2">
                <strong>개인정보보호 담당자</strong><br />
                - 성명 : 최호식<br />
                - 소속 : 마케팅팀<br />
                - 직책 : 마케팅팀 팀원<br />
                - 이메일 : hosikk@sk.com<br />
                - 전화번호 : 064-793-6067
              </p>
              <p class="numText1">
                2. 기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 정보보호마크 인증위원회, 대검찰청
                인터넷범죄수사센터, 경찰청 사이버 범죄 수사대 등으로 문의 하실 수 있습니다.
              </p>
              <p class="numText2">
                <strong>개인정보침해신고센터</strong><br />
                - 전화 : 국번없이 118<br />
                - URL : <a href="http://privacy.kisa.or.kr">http://privacy.kisa.or.kr</a><br /><br />
              </p>

              <p class="numText2">
                <strong>정보보호마크 인증위원회</strong><br />
                - 전화 : 02-580-0533<br />
                - URL : <a href="http://www.eprivacy.or.kr">http://www.eprivacy.or.kr</a><br /><br />
              </p>

              <p class="numText2">
                <strong>대검찰청 인터넷범죄수사센터</strong><br />
                - 전화 : 02-3480-2000<br />
                - URL : <a href="www.spo.go.kr">www.spo.go.kr</a><br /><br />
                <strong>경찰청 사이버 범죄 수사대</strong><br />
                - URL : <a href="http://www.police.go.kr">http://www.police.go.kr</a>
              </p>
            </section>

            <section id="section-10" class="mt-5">
              <h5 class="mb-4">
                <strong>제10조 개인정보의 안전성 확보조치에 관한 사항</strong>
              </h5>
              <p class="numText1">
                1. SK핀크스(주)는 회원의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록
                안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
              </p>
              <p class="numText2">
                가. 개인정보 취급 직원의 최소화 및 교육 개인정보취급자의 지정을 최소화하고 정기적인 교육을 시행하고
                있습니다.<br />
              </p>

              <p class="numText2">
                나. 내부관리계획의 수립 및 시행 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
                있습니다.<br />
              </p>

              <p class="numText2">
                다. 개인정보의 암호화 회원의 개인정보와 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수
                있으며 파일 및 전송 데이터를 암호화를 통해 보호되고 있습니다.<br />
              </p>

              <p class="numText2">
                라. 해킹 등에 대비한 기술적 대책 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
                보안프로그램을 설치하고 주기적인 갱신•점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고
                기술적/물리적으로 감시 및 차단하고 있습니다.<br />
              </p>

              <p class="numText2">
                마. 개인정보에 대한 접근 제한 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여•변경•말소를
                통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                외부로부터의 무단 접근을 통제하고 있습니다.<br />
              </p>

              <p class="numText2">
                바. 접속기록의 보관 및 위변조 방지 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고
                있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br />
              </p>

              <p class="numText2">
                사. 문서보안을 위한 잠금장치 사용 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한
                장소에 보관하고 있습니다.<br />
              </p>

              <p class="numText2">
                아. 비 인가자에 대한 출입 통제 개인정보를 보관하고 있는 물질적 보관장소를 별도로 두고 이에 대해 출입통제
                절차를 수립, 운영하고 있습니다.
              </p>
            </section>

            <section id="section-11" class="mt-5">
              <h5 class="mb-4">
                <strong>제11조 광고성 정보 전송</strong>
              </h5>
              <p class="numText1">
                1. SK핀크스(주)는 고객의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다.
              </p>
              <p class="numText1">
                2. SK핀크스(주)는 제품정보 안내 등 온라인 마케팅을 위해 광고성 정보를 이메일 등으로 전송하는 경우 이메일
                제목란 및 본문란에 다음 사항과 같이 고객께서 쉽게 알아 볼 수 있도록 조치합니다.
              </p>
              <p class="numText2">- 이메일 제목란 : (광고)라는 문구 및 전송자의 명칭을 제목란에 표시합니다.<br /></p>

              <p class="numText2">
                - 이메일 본문란 : 회원 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전화번호를 명시합니다. 회원
                수신거부의 의사를 쉽게 표시할 수 있는 방법을 각각 명시합니다.
              </p>
              <p class="numText1">
                3. 광고수신에 동의한 고객에게 팩스ㆍ휴대폰 문자전송 등 전자우편 이외의 방법을 통해 영리목적의 광고성
                정보를 전송하는 경우에도 전송자의 명칭을 표기하는 등 필요한 조치를 취합니다.
              </p>
            </section>

            <section id="section-12" class="mt-5">
              <h5 class="mb-4">
                <strong>제12조 개인정보 처리방침의 변경에 관한 사항</strong>
              </h5>
              <p>
                개인정보 처리방침은 2013년 9월1일에 제정되었으며 법령, 정책 또는 보안기술의 변경에 따라 내용의 추가,
                삭제 및 수정이 있을 시에는 홈페이지를 통해 변경사유 및 내용을 공지하도록 하겠습니다.
              </p>
              <p class="numText2" style="margin-bottom: 0px">- 최초 공고일자 : 2013년 9월 01일</p>

              <p class="numText2" style="margin-bottom: 0px">- 변경일자 : 2018년 11월 1일</p>

              <p class="numText2" style="margin-bottom: 0px">- 시행일자 : 2018년 11월 23일</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacyinfo',
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '개인정보 처리 방침'});
  },
};
</script>

<style scoped lang="scss">
p,
h1,
h2,
h3,
h4,
h5,
td {
  word-break: keep-all;
}
.bookmark-links a {
  float: left;
  display: block;

  border: 1px solid #bfbfbf;
  background-color: #f4f2eb;
  padding: 0.5rem;
  margin: 0.1rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  letter-spacing: -0.05rem;
}
td {
  vertical-align: middle;
}
a {
  color: #333;
}

.numText1 {
  margin-left: 1rem;
  text-indent: -1rem;
  font-weight: bold;
}
.numText2 {
  margin-left: 2.5rem;
  text-indent: -1.5rem;
}
.numText3 {
  margin-left: 2.5rem;
  text-indent: 0rem;
}
.innerText {
  margin: 1rem;
}
table_line_align {
  font-size: 0.88rem;
  margin-left: 1rem;
  text-indent: -1rem;
}

@media (min-width: 1200px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links a {
    width: 33%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links a {
    width: 49%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links a {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links a {
    width: 100%;
  }
}
</style>
